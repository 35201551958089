<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-drawer :title="title" width="50%" :closable="false" :visible="visible" @close="onClose(false)" v-if="visible">
    <div>
      <a-tree checkable :tree-data="$store.state.app.cityDict" :selectedKeys="selectedKeys" :checkedKeys="checkedKeys" :replace-fields="replaceFields" @check="checkTree" />
    </div>
    <div :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#ffffff',
        textAlign: 'right',
        zIndex: 1,
      }">
      <a-button style="margin-right: 8px" @click="onClose(false)">关闭</a-button>
      <a-button type="primary" @click="handleOk">提交</a-button>
    </div>
  </a-drawer>
</template>
<script>
import { reactive, toRefs, ref } from 'vue'
import { toRaw } from 'vue'
import { office, officeDetail } from '@/api/system/employee'
import { message } from 'ant-design-vue'
export default {
  computed: {
  },

  setup () {
    const state = reactive({
      roleId: null,
      visible: false,
      loading: false,
      selectedKeys: ref([]),
      checkedKeys: ref([]),
      replaceFields: {
        children: 'children',
        title: 'label',
        key: 'value'
      }
    })

    const loadData = async (roleId) => {
      state.roleId = roleId
      state.loading = true
      officeDetail(roleId, {}).then((res) => {
        state.checkedKeys = res.data
      })
      state.loading = false
    }
    const onClose = (recode) => {
      state.visible = recode
    }
    const checkTree = (selectedKeys, e) => {
      state.checkedKeys = selectedKeys
    }
    const handleOk = () => {
      const res = new Map()
      const checkedKeys = toRaw(state.checkedKeys).filter((key) => key !== null && key !== '' && !res.has(key) && res.set(key, 1))
      // 保存当前角色与资源的关系
      office({ empId: state.roleId, cityList: checkedKeys }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }

    return {
      loadData,
      ...toRefs(state),
      onclick,
      onClose,
      checkTree,
      handleOk
    }
  }
}
</script>
